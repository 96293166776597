import { $, addAction, INIT } from '@situation/setdesign.util';
import dayjs from 'dayjs';

const data = window.messageModals || [];
const blockClass = 'countdown-timer__block';
const valueClass = 'countdown-timer__value';
const labelClass = 'countdown-timer__label';
const locateAlternateEndTime = (id) => data.find((dataObj) => id === dataObj.id)?.end;
const formatTimePart = (value, label) => (value >= 0
    ? `<div class="${blockClass}">`
          + `<span class="${valueClass}">${value}</span>`
          + ` <span class="${labelClass}">${label}</span>`
          + '</div>'
    : '');

const countdownTimer = () => {
    $('.countdown-timer').each((_, el) => {
        const $countdown = $(el);
        const dateStr = $countdown.data('endTime')
            || locateAlternateEndTime($countdown.closest('.modal').attr('id'));
        if (!dateStr) {
            return;
        }

        const targetDate = dayjs(dateStr);
        const intervalId = setInterval(() => {
            const secondsLeft = (targetDate - dayjs()) / 1000;
            if (secondsLeft < 0) {
                clearInterval(intervalId);
                $countdown.remove();
                return;
            }

            const timeParts = [
                { value: Math.floor(secondsLeft / 86400), label: 'days' },
                { value: Math.floor((secondsLeft % 86400) / 3600), label: 'hours' },
                { value: Math.floor((secondsLeft % 3600) / 60), label: 'minutes' },
                { value: Math.floor(secondsLeft % 60), label: 'seconds' },
            ];
            const start = timeParts.findIndex((part) => part.value !== 0);
            const trimmedTimeParts = start >= 0 ? timeParts.slice(start) : timeParts;
            const countdownHtml = trimmedTimeParts
                .map((part) => formatTimePart(part.value, part.label))
                .join('');
            $countdown.html(countdownHtml);
        }, 1000);
        $countdown.data('intervalId', intervalId);
    });
};

addAction(INIT, countdownTimer);
